<template>
  <v-container fluid>
    <BaseBreadcrumb
      class="py-2"
      :title="page.title"
      :icon="mdiFileExcelOutline"
    ></BaseBreadcrumb>

    <v-data-table
      :loading="isTableLoading"
      loading-text="Cargando..."
      :server-items-length="itemsTotalCount"
      :options.sync="tableOptions"
      :headers="tableHeaders"
      item-key="id"
      :items="items"
      sort-by="itemDateTime"
      class="elevation-4"
      :sort-desc="true"
      disable-sort
      :footer-props="{
        'items-per-page-options': [5, 10, 25, 50]
      }"
    >
      <template v-slot:top class="pb-2">
        <v-row no-gutters class="pa-4">
          <v-btn
            :disabled="isTableLoading"
            color="primary"
            @click="onCreateItemDialog"
            class="ma-2 btn--expanded"
          >
            <v-icon left>
              {{ mdiPlus }}
            </v-icon>
            Nueva combinación
          </v-btn>

          <v-spacer></v-spacer>

          <v-btn
            :disabled="isTableLoading"
            outlined
            color="primary"
            @click="getItems()"
            class="ma-2 btn--expanded"
          >
            <v-icon left>
              {{ mdiRefresh }}
            </v-icon>
            Refrescar
          </v-btn>
        </v-row>

        <v-row no-gutters class="mb-n4" v-if="userTeams.length > 1">
          <v-col cols="12" md="6" xl="4">
            <v-select
              dense
              class="mx-4 mt-2"
              outlined
              :items="userTeams"
              v-model="onUserTeamActive"
              @change="onUserTeamActiveUpdated"
              label="Equipo activo"
            ></v-select>
          </v-col>
        </v-row>
      </template>

      <template v-slot:[`item.itemDateTime`]="{ item }">
        {{ new Date(item.itemDateTime).toLocaleString() }}
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <v-icon small class="mr-1" :color="getStatusColor(item.status)">
          {{ mdiCircle }}
        </v-icon>
        {{ getStatusText(item.status) }}
      </template>

      <template v-slot:[`item.id`]="{ item }">
        <div class="id-column-flow tree-dots margin-auto">
          <span>{{ item.id }}</span>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                class="ml-2 copy-icon"
                medium
                @click="onClickCopyId(item.id)"
              >
                mdi-content-copy
              </v-icon>
            </template>
            <span>Copiar</span>
          </v-tooltip>
        </div>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
              @click="getItemDetail(item.id)"
            >
              <v-icon>
                mdi-eye-outline
              </v-icon>
            </v-btn>
          </template>
          <span>Mostrar detalle</span>
        </v-tooltip>
      </template>

      <template v-if="getItemsError" v-slot:no-data>
        <v-btn outlined class="ma-4" color="primary" @click="getItems()">
          Reintentar
        </v-btn>
      </template>

      <template v-slot:footer.prepend>
        <v-row>
          <v-spacer> </v-spacer>
          <v-btn
            icon
            class="mr-4"
            @click="loadFirstsItems"
            :disabled="isTableLoading || tableOptions.page === 1"
          >
            <v-icon>
              {{ mdiChevronDoubleLeft }}
            </v-icon>
          </v-btn>

          <v-btn
            icon
            class="mr-8"
            @click="loadLastsItems"
            :disabled="
              isTableLoading ||
                tableOptions.page * tableOptions.itemsPerPage >= itemsTotalCount
            "
          >
            <v-icon>
              {{ mdiChevronDoubleRight }}
            </v-icon>
          </v-btn>
        </v-row>
      </template>
    </v-data-table>

    <v-dialog scrollable max-width="800" v-model="createItemDialog">
      <v-form ref="form">
        <v-card>
          <v-card-title class="text-h5">
            Nueva combinación
          </v-card-title>

          <v-card-text>
            <v-text-field
              class="mt-2"
              label="Nombre de la combinación*"
              v-model="item.name"
              dense
              outlined
              required
            ></v-text-field>

            <v-text-field
              label="Nombre del sobre*"
              v-model="item.documentSetName"
              class="mb-1"
              outlined
              dense
              required
            ></v-text-field>

            <v-row no-gutters>
              <v-spacer></v-spacer>
              <v-btn
                class="mb-2"
                outlined
                small
                text
                :loading="refreshingFlows"
                color="primary"
                @click="refreshFlows()"
                ><v-icon left>{{ mdiRefresh }}</v-icon
                >Cargar más flujos</v-btn
              >
            </v-row>

            <v-select
              label="Flujos"
              :items="correspondenceCombinationFlowTokenIds"
              v-model="item.flowTokenId"
              required
              outlined
              dense
            ></v-select>

            <v-text-field
              label="Referencia"
              v-model="item.reference"
              class="mb-1"
              outlined
              dense
            ></v-text-field>

            <v-file-input
              outlined
              dense
              show-size
              small-chips
              @change="onExcelDocumentChanged"
              accept=".xlsx, .csv"
              placeholder="Selecciona un fichero de datos (*.xlsx; *.csv)"
              prepend-icon="mdi-file-excel"
              :key="inputKey"
            ></v-file-input>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              @click="[(createItemDialog = false), resetItem()]"
              text
              color="primary"
            >
              Cerrar
            </v-btn>

            <v-btn
              text
              color="primary"
              @click="createItem"
              :disabled="!isItemValid"
              :loading="isItemLoading"
              >Continuar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <!--Show Detail -->
    <v-dialog scrollable v-model="showItemDialog" width="auto">
      <v-card>
        <v-card-title class="text-h5">
          Detalle
        </v-card-title>

        <v-card-text>
          <h4>Nombre de la combinación</h4>
          <p>
            {{ detailItem.name }}
          </p>

          <h4>Nombre del sobre</h4>
          <p>
            {{ detailItem.documentSetName }}
          </p>

          <h4>Id del flujo</h4>
          <p>
            {{ detailItem.flowTokenId }}
          </p>

          <h4>Referencia</h4>
          <p>
            {{ detailItem.reference ? detailItem.reference : "-" }}
          </p>

          <h4>Estado</h4>
          <p>
            <v-icon
              small
              class="mr-1 pb-1"
              :color="getStatusColor(detailItem.status)"
            >
              {{ mdiCircle }}
            </v-icon>
            <span>{{ getStatusText(detailItem.status) }}</span>
          </p>

          <h4>Documento excel (xlsx)</h4>
          <v-row no-gutters align="center">
            <span>
              {{ detailItem.dataFile ? detailItem.dataFile.name : "" }}
            </span>
            <v-btn color="primary" @click="downloadDetailItemDocument()" icon>
              <v-icon>mdi-download</v-icon>
            </v-btn>
          </v-row>

          <div class="mt-1" v-if="detailItem.infoDocumentSets">
            <h4>Información adicional</h4>
            <span>
              Total de sobres a enviar: {{ detailItem.infoDocumentSets.total }}
            </span>
            <br />
            <span>
              Total de sobres enviados: {{ detailItem.infoDocumentSets.sent }}
            </span>
          </div>

          <div
            v-if="
              detailItem.infoDocumentSets &&
                detailItem.infoDocumentSets.rowError
            "
          >
            <p>
              Filas de error de sobre:
              {{ detailItem.infoDocumentSets.rowError.join() }}
            </p>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="
              showItemDialog = false;
              detailItem = {};
            "
          >
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { FileToB64 } from "../../../utils/utils";
import {
  mdiFileExcelOutline,
  mdiRefresh,
  mdiPlus,
  mdiCircle,
  mdiChevronDoubleLeft,
  mdiChevronDoubleRight
} from "@mdi/js";

export default {
  name: "CorrespondenceCombination",
  data: () => ({
    page: {
      title: "Combinación de correspondencia"
    },
    tableOptions: {},
    mdiFileExcelOutline: mdiFileExcelOutline,
    mdiRefresh: mdiRefresh,
    mdiPlus: mdiPlus,
    mdiCircle: mdiCircle,
    mdiChevronDoubleRight: mdiChevronDoubleRight,
    mdiChevronDoubleLeft: mdiChevronDoubleLeft,
    tableHeaders: [
      { text: "NOMBRE", value: "name" },
      {
        text: "ID",
        value: "id"
      },
      { text: "ESTADO", value: "status" },
      {
        text: "FECHA",
        value: "itemDateTime"
      },
      {
        text: "ACCIONES",
        value: "actions"
      }
    ],
    isTableLoading: false,
    isItemLoading: false,
    refreshingFlows: false,
    getItemsError: false,
    documentStatus: [
      { value: 100, text: "Creado" },
      { value: 200, text: "En proceso" },
      { value: 300, text: "Completado" },
      { value: 301, text: "Completado con errores" },
      { value: 800, text: "Erróneo" }
    ],
    createItemDialog: false,
    showItemDialog: false,
    item: {},
    detailItem: {},
    inputKey: 0,
    onUserTeamActive: null
  }),

  computed: {
    correspondenceCombinationFlowTokenIds() {
      let flows = this.$store.getters.flows;
      if (flows) {
        let flowsCorrespondenceCombination = flows.filter(flow => {
          return flow.flowType == 1;
        });
        let parsedFlows = flowsCorrespondenceCombination.map(flow => {
          return { value: flow.flowTokenId, text: flow.name };
        });
        return parsedFlows;
      }
      return [];
    },

    items() {
      return this.$store.state.correspondenceCombinationItems;
    },

    itemsTotalCount() {
      return this.$store.getters.countCorrespondenceCombinationItems;
    },

    isItemValid() {
      var parsedItem = JSON.parse(JSON.stringify(this.item));
      return (
        parsedItem.name &&
        parsedItem.flowTokenId &&
        parsedItem.dataFile &&
        parsedItem.documentSetName
      );
    },
    userTeams() {
      var userProfile = this.$store.getters["user/userProfile"];
      if (userProfile && userProfile.teams) {
        return userProfile.teams.map(team => {
          return { value: team.teamTokenId, text: team.name };
        });
      }
      return [];
    },
    userTeamActive() {
      return this.$store.getters.activeTeamTokenId;
    }
  },
  mounted() {
    this.onUserTeamActive = this.userTeamActive;
  },
  watch: {
    tableOptions: {
      deep: true,
      handler() {
        this.getItems();
      }
    }
  },

  methods: {
    getItems() {
      let offset =
        this.tableOptions.itemsPerPage * (this.tableOptions.page - 1);
      let limit = this.tableOptions.itemsPerPage;
      let teams = this.userTeamActive;
      this.isTableLoading = true;
      this.$store
        .dispatch("getCorrespondenceCombinations", {
          limit,
          offset,
          teams
        })
        .then(() => {
          this.getItemsError = false;
        })
        .catch(err => {
          console.log(err);
          this.getItemsError = true;
        })
        .finally(() => {
          this.isTableLoading = false;
        });
    },

    getItemDetail(id) {
      this.isTableLoading = true;
      this.$store
        .dispatch("getCorrespondenceCombination", id)
        .then(item => {
          this.getItemsError = false;
          this.detailItem = item;
          this.showItemDialog = true;
        })
        .catch(err => {
          console.log("error");
          console.log(err);
          this.getItemsError = true;
        })
        .finally(() => {
          this.isTableLoading = false;
        });
    },

    onCreateItemDialog() {
      this.createItemDialog = true;
    },

    getStatusColor(status) {
      switch (status) {
        case 100:
          return "#20a9ef";
        case 200:
          return "#20a9ef";
        case 300:
          return "#52bc03";
        case 301:
          return "darkorange";
        case 800:
          return "#e54448";
        default:
          return "#20a9ef";
      }
    },

    getStatusText(status) {
      var statusFound = this.documentStatus.find(element => {
        return element.value == status;
      });
      return statusFound != undefined ? statusFound.text : "";
    },
    onClickCopyId(id) {
      navigator.clipboard.writeText(id);
      this.$toasted.global.toast_success({
        message: "Se ha copiado el identificador al portapapeles"
      });
    },

    async onExcelDocumentChanged(file) {
      if (file) {
        let fileB64 = await FileToB64(file);
        let documentType = null;
        const fileExtension = this.getFileExtension(file.name);

        if (fileExtension === "csv") {
          documentType = 1;
        } else if (fileExtension === "xlsx" || fileExtension === "xls") {
          documentType = 0;
        }

        this.item.dataFile = {
          name: file.name
            .split(".")
            .slice(0, -1)
            .join("."),
          b64Content: fileB64,
          documentType
        };
        //Tell the observer that structure item has been updated
        this.item = Object.assign({}, this.item);
      } else {
        this.item.dataFile = undefined;
        //Tell the observer that structure item has been updated
        this.item = Object.assign({}, this.item);
      }
    },
    getFileExtension(fileName) {
      const parts = fileName.split(".");
      return parts[parts.length - 1];
    },
    async createItem() {
      this.item.teamId = this.userTeamActive;
      var parsedItem = JSON.parse(JSON.stringify(this.item));
      this.isItemLoading = true;
      this.$store
        .dispatch("postCorrespondenceCombination", parsedItem)
        .then(() => {
          this.$toasted.global.toast_success({
            message:
              "La combinación de correspondencia se ha creado correctamente"
          });
          this.getItems();
          this.resetItem();
        })
        .catch(err => {
          console.log(err);
          this.$toasted.global.toast_error({
            message: "La combinación de correspondencia no se ha podido crear"
          });
          this.resetItem();
        })
        .finally(() => {
          this.isItemLoading = false;
          this.createItemDialog = false;
          this.item = Object.assign({}, {});
        });
    },

    async downloadDetailItemDocument() {
      if (this.detailItem.dataFile && this.detailItem.dataFile.b64Content) {
        let dataurl =
          "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," +
          this.detailItem.dataFile.b64Content;
        const link = document.createElement("a");
        link.href = dataurl;
        link.download = this.detailItem.dataFile.name + ".xlsx";
        link.click();
        link.remove();
      }
    },

    loadFirstsItems() {
      if (this.tableOptions.page === 1) {
        this.getItems();
      } else {
        this.tableOptions.page = 1;
      }
    },

    loadLastsItems() {
      let page = Math.ceil(
        this.itemsTotalCount / this.tableOptions.itemsPerPage
      );
      if (this.tableOptions.page !== page) {
        this.tableOptions.page = page;
      } else {
        this.getItems();
      }
    },

    refreshFlows() {
      this.refreshingFlows = true;
      this.$store
        .dispatch("getFlows", {
          limit: 100,
          offset: 0,
          active: true
        })
        .then(data => {
          console.log(data);
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.refreshingFlows = false;
        });
    },
    resetItem() {
      this.item = {};
      this.inputKey++;
    },
    onUserTeamActiveUpdated(teamTokenId) {
      this.$store.commit("SET_ACTIVE_TEAM_TOKEN_ID", teamTokenId);
      this.getItems();
    }
  }
};
</script>
<style scoped>
@media (max-width: 490px) {
  .btn--expanded {
    width: 95%;
  }
}
</style>
